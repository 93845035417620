import createPersistedState from "use-persisted-state"

export function useAccount() {
  const [me, setMe] = createPersistedState("nostalgie.account.me")({})

  function setMeAccount (me) {
    setMe(me ?? {});
  }

  function forgetAccount() {
    setMe({})
  }

  return {
    setMeAccount,
    me,
    forgetAccount,
  }
}
