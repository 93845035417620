import { useTranslation } from "react-i18next"
import { SUPPORTED } from "src/i18n/i18next"

export default function usePreferences() {
  const dateFormat = "dd/LL/yyyy"
  const { i18n } = useTranslation()

  return {
    dateFormat,
    language: i18n.language,
    languages: SUPPORTED,
  }
}
