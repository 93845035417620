import createPersistedState from "use-persisted-state"
import { useAccount } from "src/hooks/useAccount"
export function useOAuthTokens() {
  const [preprJwtToken, setPreprJwtToken] = createPersistedState('nostalgie.oauth.preprJwtToken')({});
  const { forgetAccount } = useAccount();

  function setOAuthTokens({ accessToken }) {
    setPreprJwtToken({token: accessToken});
  }

  function forgetOAuthTokens() {
    setPreprJwtToken({});
    forgetAccount();
  }

  function clearOAuthTokens() {
    setPreprJwtToken({});
  }

  const accessToken = preprJwtToken?.token

  return {
    setOAuthTokens,
    accessToken,
    forgetOAuthTokens,
    clearOAuthTokens
  };
}
