import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import nl from "./translations/nl"

export const SUPPORTED = ["nl"]
export const FALLBACK = "nl"

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV === "development",
    initImmediate: false,
    returnObjects: true,
    preload: SUPPORTED,
    whitelist: SUPPORTED,
    fallbackLng: FALLBACK,
    resources: {
      nl: { translation: nl },
    },
  })

export default i18n
